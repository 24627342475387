import React from "react";
import { Container } from "react-bootstrap";
import Acnavbar from "../components/Acnavbar";
import "../assets/scss/index.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import Footer from "../components/footer";
import Contact from "../components/Contact";
import ProductDescription from "../components/ProductDescription";
import Seo from "../shared/seo";
import Wrapper from "../components/Wrapper";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import "../assets/scss/detailSuccessStory.scss";
import successStoryValues from "../json/success-story.json";
import StoryCard from "../components/StoryCard";

function detailSuccessStory(Context: any) {
  const { successStories, id } = Context.pageContext;
  const renderContent = (content: any) => {
    if (typeof content === "string") {
      return <p className="fw-400 lh-30 lh-sm-20">{content}</p>;
    } else if (Array.isArray(content)) {
      return (
        <div className="px-3">
          <ul className="fw-400 mb-0">
            {content.map((item, index) => {
              const [beforeColon, afterColon] = item.split(":");
              return (
                <li
                  className="fnt-18 fnt-sm-14 fw-400 lh-30 lh-sm-20 mb-2"
                  key={index}
                >
                  <span className="fw-700">{beforeColon}:</span>
                  {afterColon}
                </li>
              );
            })}
          </ul>
        </div>
      );
    } else if (typeof content === "object") {
      return (
        <div>
          {Object.entries(content).map(([subTitle, subContent], subIndex) => (
            <div key={subIndex}>{renderContent(subContent)}</div>
          ))}
        </div>
      );
    }
    return null;
  };

  // Define the type for your data
  const techStack = Context?.pageContext?.methodologyAndTechStack?.Tech_Stack;

  return (
    <Wrapper>
      <div className="overflow-hidden">
        <Seo
          title={Context.pageContext.seo_title}
          description={Context.pageContext.seo_description}
        />
        <Acnavbar home={false} pathname={""} logo={""} logoLogic={false} />
        <ProductDescription
          topheader="SUCCESS STORY"
          header={Context.pageContext.companyName}
          headerText={Context.pageContext.headerText}
        />
        <Container>
          <div className="d-flex flex-column justify-content-center mx-auto mb-3">
            <div className="d-flex flex-column mx-auto pt-3 py-lg-3 mt-2 my-md-2">
              <div className="text-center fnt-24 fw-700 fnt-sm-16 lh-36 lh-sm-10 color-black pb-4">
                Case Studies
              </div>
              <div className="text-center py-4 py-lg-5">
                <LazyLoadImage
                  effect="blur"
                  src={Context.pageContext.headerInsideImg}
                  alt={Context.pageContext.companyName}
                  className="whiteLogoWidth h-auto"
                />
              </div>

              <div className="color-black">
                <label className="fnt-32 fnt-sm-18 lh-44 fw-700 mb-3">
                  Introduction and Background
                </label>
                {Context.pageContext.IntroductionandBackground?.map(
                  (item: any) => {
                    return (
                      <div className="mb-3 fnt-16 fw-400 lh-30 lh-sm-20 mobCompanyDetail fnt-sm-14 fnt-lg-18">
                        {item}
                      </div>
                    );
                  }
                )}
              </div>
              <div className="detailSucces my-lg-3">
                <div className="d-md-flex flex-md-row flex-column justify-content-between">
                  <div className="w-100  mb-3 mb-md-0 me-md-3 me-lg-3">
                    <LazyLoadImage
                      effect="blur"
                      src={Context.pageContext.videoUrl}
                      alt={Context.pageContext.companyName}
                      className="w-100"
                    />
                  </div>
                  <div className="w-100 mb-3 mb-md-0 me-md-3 me-lg-3">
                    <LazyLoadImage
                      effect="blur"
                      src={Context.pageContext.videoUrl}
                      alt={Context.pageContext.companyName}
                      className="w-100"
                    />
                  </div>
                </div>
              </div>
              <div className="color-black">
                {successStories &&
                  Object.entries(successStories).map(
                    ([title, content], index) => (
                      <div key={index} className="my-lg-3">
                        <label className="fnt-32 fnt-sm-18 lh-44 fw-700 mb-3">
                          {title}
                        </label>
                        <div className="fnt-18 fnt-sm-14 fw-400 lh-30">
                          {renderContent(content)}
                        </div>
                      </div>
                    )
                  )}
              </div>
            </div>
          </div>
        </Container>

        <div>
          {Context.pageContext.methodologyAndTechStack && (
            <div
              className="px-3 py-4 py-lg-5"
              style={{ backgroundColor: "#2B3351", color: "#ffffff" }}
            >
              <Container>
                <label className="fnt-32 fnt-sm-18 lh-44 fw-700 ">
                  Methodology
                </label>
                <div className="fnt-18 fnt-sm-14 fw-400 my-3">
                  {Context?.pageContext?.methodologyAndTechStack?.Methodology}
                </div>
                <div className="my-3 fw-700 fnt-32 fnt-sm-18">Tech Stack</div>
                <div className="row">
                  {techStack &&
                    Object?.entries(techStack)?.map(
                      ([category, technologies]) => (
                        <div
                          key={category}
                          className="col-12 col-md-6 col-lg-4 "
                        >
                          <div className="tech-stackcard p-4 pb-2 mb-3">
                            <h2 className="text-dark fw-700 fnt-20 mb-4">
                              {category.replace(/_/g, " ")}
                            </h2>

                            <div className="mb-3 ">
                              {Array.isArray(technologies) &&
                                technologies?.map((tech: any) => (
                                  <div className="d-flex flex-row justify-content-between align-items-center mb-3">
                                    <div
                                      key={tech}
                                      className="text-dark fw-500 fnt-18"
                                    >
                                      {tech.tech}
                                    </div>
                                    <LazyLoadImage
                                      effect="blur"
                                      src={tech.img}
                                      alt={"tech-image"}
                                      className="tech-image"
                                    />
                                  </div>
                                ))}
                            </div>
                          </div>
                        </div>
                      )
                    )}
                </div>
              </Container>
            </div>
          )}
        </div>

        <Container className="py-4 py-lg-5">
          <div className="d-flex flex-column justify-content-center mx-auto mb-3">
            <div>
              {Context.pageContext.descriptionsAndAnalysis && (
                <div className="mb-3">
                  <label className="fnt-32 fnt-sm-18 lh-44 fw-700 mb-3">
                    Descriptions and Analysis
                  </label>
                  <div className="fnt-18 fnt-sm-14 fw-400 lh-30">
                    {renderContent(Context.pageContext.descriptionsAndAnalysis)}
                  </div>
                </div>
              )}
            </div>
            <div className="detailSucces my-lg-3">
              <div className="d-md-flex flex-md-row flex-column justify-content-between">
                <div className="w-100 mb-3 mb-md-0 me-md-3 me-lg-3">
                  <LazyLoadImage
                    effect="blur"
                    src={Context.pageContext.videoUrl}
                    alt={Context.pageContext.companyName}
                    className="w-100"
                  />
                </div>
                <div className="w-100">
                  <LazyLoadImage
                    effect="blur"
                    src={Context.pageContext.videoUrl}
                    alt={Context.pageContext.companyName}
                    className="w-100"
                  />
                </div>
              </div>
            </div>
            <div>
              {Context.pageContext.resultsAndConclusions && (
                <div className="my-3">
                  <label className="fnt-32 fnt-sm-18 lh-44 fw-700 mb-3">
                    Results and Conclusions
                  </label>
                  <div className="fnt-18 fnt-sm-14 fw-400 lh-30">
                    {renderContent(Context.pageContext.resultsAndConclusions)}
                  </div>
                </div>
              )}
            </div>
            <div>
              <label className="fnt-32 lh-44 fnt-sm-18 fw-700 my-3">
                Client testimonial
              </label>
              <div className="border commentSection mx-auto p-2 p-lg-3">
                <div className="d-flex">
                  <div className="me-3">
                    <LazyLoadImage
                      effect="blur"
                      className="rounded-circle profileImageSuccessStory"
                      src={Context.pageContext.profileIcon}
                      alt={Context.pageContext.name}
                    />
                  </div>
                  <div>
                    <div className="fw-700 fnt-16 lh-26 fnt-sm-14 lh-sm-24">
                      {Context.pageContext.name}
                    </div>
                    <div className="fw-700 fnt-16 lh-26 fnt-sm-12 lh-sm-22 color-grey">
                      {Context.pageContext.designation}
                    </div>
                  </div>
                </div>
                <div className="d-flex mt-3">
                  <LazyLoadImage
                    effect="blur"
                    src="/colon.webp"
                    alt="Semicolon Icon"
                    className="ms-2 me-3"
                  />
                  <div className="d-none d-lg-block h-100">
                    <LazyLoadImage
                      effect="blur"
                      src="/line.webp"
                      alt="Vertical Line Icon "
                      className="me-3 "
                    />
                  </div>
                  <div className="d-lg-none">
                    <LazyLoadImage
                      effect="blur"
                      src="/lineMob.webp"
                      alt="Vertical Line Icon For Mobile "
                      className="me-3 "
                    />
                  </div>
                  <p className="fw-600 fnt-16 lh-26 fnt-sm-12 lh-sm-22 color-grey-light">
                    {Context.pageContext.comment}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Container>

      <div className="success-stories-bg py-4 py-lg-5">
        <div className="text-white fnt-32 fnt-sm-18 fw-500 text-center mb-4 ">Other success stories</div>
      <Container>
          <div className="row justify-content-between">
            {successStoryValues
              .filter((company: any) => company.id !== id)
              .slice(0, 3)
              .map((company: any) => (
                <div
                  key={company.id}
                  className="col-12 col-md-6 col-lg-4 mb-4 mb-md-0"
                >
                  <StoryCard
                    companyImg={company?.headerInsideImg}
                    companyName={company.companyName}
                    companyText={company?.headerText}
                    techStack={
                      company?.methodologyAndTechStack?.Tech_Stack
                        ?.Frontend_Development
                    }
                    bgColor={company?.backgroundColor}
                    slug={company?.slug}

                  />
                </div>
              ))}
          </div>
        </Container>
      </div>
       

        <div className="bg-white">
          <Contact />
        </div>
        <Footer />
      </div>
    </Wrapper>
  );
}

export default detailSuccessStory;
